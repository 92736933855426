@{GC} {
    background: rgba(231, 233, 239, 0.5);
    padding: 60px 0;

    h1 {
        font-size: 40px;
        line-height: 60px;
        color: #014050;
        letter-spacing: 2px;
        margin-bottom: 60px;

        @media @max768 {
            font-size: 28px;
            line-height: normal;
        }
    }
}




















@GC : .green-content;