@{banner} {
    @media @max768 {
        padding: 0 20px;
    }
    @{banner}__header {
        h1 {
            font-size: 40px;
            color: #014050;
            letter-spacing: 2px;

            @media @max768 {
                letter-spacing: normal;
                font-size: 28px;
            }
        }

        p {
            font-size: 20px;
            max-width: 800px;
            color: #4A7E9F;

            @media @max768 {
                font-size: 18px;
            }
        }
    }

    @{banner}__steps {
        p {
            margin: 0;
        }
        @media @max768 {
            .ant-col + .ant-col {
                margin-top: 40px;
            }
        }
    }

    @{banner}__content {
        h1 {
            margin: 0 auto;
            max-width: 769px;
            color: #014050;
            font-size: 25px;
            @media @max768 { 
                font-size: 20px;
            }
        }
    }

    @{banner}__slides {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 40px 0;
        @media @max768 { 
            padding: 40px 30px;
        }
    }
}

@banner: .banner;