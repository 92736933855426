.dashboard-tab{
    .ant-tabs-nav-wrap {
        justify-content: flex-end;
        .ant-tabs-nav-list {
            .ant-tabs-tab + .ant-tabs-tab {
                &::before{
                    position: absolute;
                    content: '';
                    height: 19px;
                    width: 1px;
                    left: -16px;
                    border-left: 1px solid #C6C6C6;
                }
            }
        }

        @media  @max768 {
            justify-content: flex-start;
        }
    }

    &.tab-left {
        .ant-tabs-nav-wrap {
            justify-content: flex-start;
        }
    }
}

@media @max768 {
    .main-tab {
        padding-top: 60px !important;
    }

    .main-tab > .ant-tabs-nav {
        position: fixed !important;
        top: 72px;
        left: 24px;
        background: #f0f2f5;
        width: 100%;
        z-index: 9;
    }
}