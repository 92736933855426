.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
        font-weight: 600;
    }
}

.ant-dropdown-menu-item-selected{
    background-color: lighten(@gray, 15%);
}

.ant-divider-horizontal {
    margin: 0; 
}

.ant-input-affix-wrapper {
    padding: 8px 11px !important;
}

.ant-picker-footer {
    display: none;
  }

.ant-btn {
    height: 40px;
    font-weight: 600;
}

a.ant-btn {
    line-height: 37px;
}

.ant-form {
    label {
        font-size: 12px !important;
        color: @secondary-color;
    }
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    font-size: 18px
}

.ant-menu-inline {
    .ant-menu-item::after {
        right: auto;
        left: 0;
        border-color: #3497DA;
    }
}

.ant-form-item {
    margin-bottom: 18px !important;
}
.ant-form-item-with-help {
    margin-bottom: 0 !important;
}
.ant-form-item-explain{
    // min-height: 18px !important;
    font-size: 12px !important;
}

a {
    color: @secondary-color;
}

.ant-form-item-control-input-content{
    & > input {
        height: 40px;
    }

    .ant-select {
        .ant-select-selector {
            height: 40px !important;
            align-items: center;
        }
    }
}

.ant-table-thead > tr > th {
    font-weight: 600 !important;
}

.ant-tabs-tab-btn {
    font-weight: 600;
}

.ant-layout-sider-children {
    background-color: @white;
}

.rec.rec-pagination {
  display: none;
}

.rec.rec-arrow {
  background-color: white !important;
}

.rec.rec-arrow:hover {
  background-color: #49CB5C !important;
}

.rec.rec-arrow:focus:enabled {
  background-color: #49CB5C !important;
  color: white;
}

.rec.rec-arrow:disabled {
  background-color: white !important;
  cursor: not-allowed;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    font-size: 30px !important;
    @{c-var}: rgba(darken(@gray, 20%), 0.4) !important;

    &:hover {
        @{c-var}: @primary-color !important;
    }
}

.ant-carousel .slick-next {
    right: -30px !important;
}

.ant-carousel .slick-prev {
    left: -30px !important;
}

@media @max768 {
    .ant-menu-inline-collapsed-tooltip {
        display: none !important;
    }
}