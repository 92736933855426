body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2 {
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('/src/assets/fonts/Gotham/Gotham-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/src/assets/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.field-label {
  display: block;
  padding-bottom: 4px;
  font-weight: 500;
}
.field-label.required::after {
  display: inline-block;
  margin-left: 2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.table-actions span {
  display: flex;
}
.form_body .ant-form-item-has-error input {
  border-color: #ff4d4f !important;
}
.max-width {
  width: 100%;
}
.width-300 {
  width: 300px;
}
.mobile-header {
  max-width: 270px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.b-radius-20 {
  border-radius: 20px;
}
.b-radius-10 {
  border-radius: 10px;
}
.profile-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e7e7e7;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}
.d-block {
  display: block;
}
.visible-xs {
  display: none !important;
}
.d-i-block {
  display: inline-block;
}
.max-height {
  height: 100%;
}
.icon-left .ant-picker-suffix {
  position: absolute;
  margin: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.85);
}
.icon-left input {
  padding-left: 18px !important;
}
.ant-form-item-has-error .icon-left .ant-picker-suffix {
  color: #ff4d4f;
}
.custom-tooltip {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
}
.dynamic-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dynamic-info h2 {
  margin: 0;
  font-weight: 600;
}
.dynamic-info p {
  text-align: center;
  margin: 20px 0;
}
.bg-primary {
  background-color: #00AD4C;
}
.bg-secondary {
  background-color: #014050;
}
.bg-peach {
  background-color: #F7CB73;
}
.bg-green {
  background-color: #52C41A;
}
.bg-white {
  background-color: #ffffff;
}
.box-shadow {
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.padding-s {
  padding: 10px;
}
.padding-s-v {
  padding: 10px 0;
}
.pad-bottom-s {
  padding-bottom: 10px;
}
.padding-m {
  padding: 20px;
}
.padding-m-v {
  padding: 20px 0;
}
.pad-bottom-s-s {
  padding-bottom: 8px;
}
.r-margin {
  margin: 0 !important;
}
.margin-top-m {
  margin-top: 20px;
}
.margin-bottom-m {
  margin-bottom: 20px;
}
.margin-m-v {
  margin: 20px 0;
}
.margin-right-s {
  margin-right: 10px;
}
.space-left-s-xs {
  margin-left: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-26 {
  font-size: 30px;
}
.font-30 {
  font-size: 30px;
}
.font-35 {
  font-size: 35px;
}
.font-60 {
  font-size: 60px;
}
.fw-600 {
  font-weight: 600;
}
.fw-400 {
  font-weight: 450;
}
.text-primary {
  color: #00AD4C;
}
.text-secondary {
  color: #014050;
}
.text-blue {
  color: #3497DA;
}
.text-white {
  color: #ffffff;
}
.text-error {
  color: #EA3B3B;
}
.link-secondary,
.text-gray {
  color: #999999;
}
.link-primary:hover {
  color: #00AD4C;
}
.link-secondary:hover {
  color: #014050;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.c-pointer {
  cursor: pointer;
}
.tableClass th {
  color: #00AD4C;
}
.tableClassOne thead > tr > th {
  color: 'pink';
}
@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }
  .max-width-xs {
    width: 100%;
  }
  .d-header,
  .hidden-xs {
    display: none;
  }
  .mobile-add {
    position: fixed;
    right: 14px;
    bottom: 14px;
    width: 50px !important;
    height: 50px !important;
    z-index: 1;
  }
  .space-top-m-xs {
    margin-top: 20px;
  }
  .space-left-s-xs {
    margin-left: 0;
  }
  .action-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .action-container * {
    width: 100%;
    margin: 0 !important;
    padding: 0;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 600;
}
.ant-dropdown-menu-item-selected {
  background-color: #f2f2f2;
}
.ant-divider-horizontal {
  margin: 0;
}
.ant-input-affix-wrapper {
  padding: 8px 11px !important;
}
.ant-picker-footer {
  display: none;
}
.ant-btn {
  height: 40px;
  font-weight: 600;
}
a.ant-btn {
  line-height: 37px;
}
.ant-form label {
  font-size: 12px !important;
  color: #014050;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  height: 40px;
  font-size: 18px;
}
.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
  border-color: #3497DA;
}
.ant-form-item {
  margin-bottom: 18px !important;
}
.ant-form-item-with-help {
  margin-bottom: 0 !important;
}
.ant-form-item-explain {
  font-size: 12px !important;
}
a {
  color: #014050;
}
.ant-form-item-control-input-content > input {
  height: 40px;
}
.ant-form-item-control-input-content .ant-select .ant-select-selector {
  height: 40px !important;
  align-items: center;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}
.ant-tabs-tab-btn {
  font-weight: 600;
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
.rec.rec-pagination {
  display: none;
}
.rec.rec-arrow {
  background-color: white !important;
}
.rec.rec-arrow:hover {
  background-color: #49CB5C !important;
}
.rec.rec-arrow:focus:enabled {
  background-color: #49CB5C !important;
  color: white;
}
.rec.rec-arrow:disabled {
  background-color: white !important;
  cursor: not-allowed;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 30px !important;
  color: rgba(153, 153, 153, 0.4) !important;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  color: #00AD4C !important;
}
.ant-carousel .slick-next {
  right: -30px !important;
}
.ant-carousel .slick-prev {
  left: -30px !important;
}
@media (max-width: 768px) {
  .ant-menu-inline-collapsed-tooltip {
    display: none !important;
  }
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999999;
}
.loader .ant-spin {
  transform: scale(1.7);
}
.breadcrumb {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.breadcrumb span + span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboard-tab .ant-tabs-nav-wrap {
  justify-content: flex-end;
}
.dashboard-tab .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab::before {
  position: absolute;
  content: '';
  height: 19px;
  width: 1px;
  left: -16px;
  border-left: 1px solid #C6C6C6;
}
@media (max-width: 768px) {
  .dashboard-tab .ant-tabs-nav-wrap {
    justify-content: flex-start;
  }
}
.dashboard-tab.tab-left .ant-tabs-nav-wrap {
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .main-tab {
    padding-top: 60px !important;
  }
  .main-tab > .ant-tabs-nav {
    position: fixed !important;
    top: 72px;
    left: 24px;
    background: #f0f2f5;
    width: 100%;
    z-index: 9;
  }
}
.manage-card {
  border-radius: 10px;
  width: 334px;
  margin: 10px;
  background-color: #ffffff;
  overflow: hidden;
}
.manage-card.detail {
  width: auto;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .manage-card {
    width: 100%;
  }
}
.manage-card .manage-card__header {
  height: 200px;
  min-width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manage-card .manage-card__content {
  padding: 0 20px;
}
.manage-card .manage-card__content h3 {
  font-family: Gotham;
  font-size: 28px;
  margin: 0;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content h3 {
    font-size: 6vw;
  }
}
.manage-card .manage-card__content h3:not(.text-blue) {
  color: #00AD4C;
}
.manage-card .manage-card__content .divider-line {
  margin: 10px 0;
  width: 20px;
  border-top: 2px solid #3497DA;
}
.manage-card .manage-card__content .divider-line.green {
  border-top: 2px solid #00AD4C;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content .divider-line.special {
    border-top: 0;
    border-left: 2px solid #3497DA;
    margin-right: 10px;
    height: 30px;
  }
  .manage-card .manage-card__content .divider-line.special.green {
    border-left: 2px solid #00AD4C;
  }
}
.manage-card .manage-card__content label {
  font-size: 14px;
  white-space: pre-wrap;
  font-weight: 600;
  color: #014050;
  margin-bottom: 20px;
  display: block;
}
.manage-card .manage-card__content label.summary {
  max-width: 150px;
}
.manage-card .manage-card__content.w-bg {
  background-color: #F8F9FD;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content.special {
    width: 100% !important;
    min-height: auto !important;
    margin: 10px !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
  }
  .manage-card .manage-card__content.special h3 {
    max-width: 50%;
  }
}
.manage-card .manage-card__footer {
  padding: 10px 20px;
  border-top: 1px solid #E7E9EF;
}
.manage-card .manage-card__footer button {
  font-size: 16px;
}
.manage-card.horizontal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.manage-card.horizontal .manage-card__header {
  min-height: 200px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip {
  display: flex;
  flex-wrap: wrap;
  width: 45%;
}
.manage-card.horizontal__card-tip .manage-card__header {
  min-height: 200px;
  height: 50%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.manage-card.horizontal__card-tip-detail .manage-card__header {
  min-height: 415px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-detail .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip-detail .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-detail .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip-detail .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip-detail .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip-detail .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip-detail .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip-other {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 150px;
}
.manage-card.horizontal__card-tip-other .manage-card__header {
  min-height: 200px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-other .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip-other .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-other .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip-other .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip-other .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip-other .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip-other .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card .special-col {
  width: calc(100% - 358px);
  justify-content: center;
  display: flex;
}
@media (max-width: 768px) {
  .manage-card .special-col {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .manage-card-list {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }
  .manage-card-list .manage-card {
    min-width: 270px;
  }
  .manage-card-list .manage-card.w-img {
    min-width: 180px;
  }
  .manage-card-list .manage-card.w-img .ant-space {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
  .manage-card-list .manage-card.w-img .ant-space.n-reverse {
    padding-top: 0;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 10;
  }
  .sidebar.ant-layout-sider-collapsed {
    margin-left: -80px;
  }
}
.sidebar--overlay {
  content: '';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.upload-card-custom .ant-upload.ant-upload-select-picture-card {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 5px;
}
@media (max-width: 768px) {
  .content_2 {
    width: 810px;
    margin: 0px auto;
  }
}
@media (max-width: 768px) {
  .content1_header {
    width: 100%;
    margin: 0px auto;
  }
}
@media (max-width: 768px) {
  .content_title {
    padding: 30px;
    width: 60%;
  }
}
@media (max-width: 768px) {
  .content4 {
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .content4 {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .navigation {
    display: none;
  }
}
@media (min-width: 1024px) {
  .burger {
    display: none;
  }
}
@media (max-width: 768px) {
  .burger {
    border: 1px solid black;
  }
}
@media (min-width: 1024px) {
  .mobile_logo {
    display: none;
  }
}
@media (max-width: 768px) {
  .mobile_logo {
    width: 100%;
    margin: 0px auto;
  }
}
@media (max-width: 768px) {
  .browser_logo {
    display: none;
  }
}
@media (max-width: 768px) {
  .mobile-nav {
    width: 300px;
    color: #014050;
  }
}
@media (min-width: 1024px) {
  .mobile-nav {
    display: none;
  }
}
.mobile-nav:hover {
  color: #71d97d;
  cursor: pointer;
}
@media (max-width: 768px) {
  .responsive-modal .ant-modal-content {
    height: auto;
    width: 790px;
    left: -25px;
    position: absolute;
  }
  .responsive-modal .ant-modal-body {
    height: auto;
    width: 790px;
  }
}
@media (max-width: 426px) {
  .responsive-modal .ant-modal-content {
    height: auto;
    width: 790px;
    left: -190px;
    position: absolute;
  }
  .responsive-modal .ant-modal-body {
    height: auto;
    width: 790px;
  }
}
@media (max-width: 376px) {
  .responsive-modal .ant-modal-content {
    height: auto;
    width: 790px;
    left: -215px;
    position: absolute;
  }
  .responsive-modal .ant-modal-body {
    height: auto;
    width: 790px;
  }
}
@media (max-width: 321px) {
  .responsive-modal .ant-modal-content {
    height: auto;
    width: 790px;
    left: -245px;
    position: absolute;
  }
  .responsive-modal .ant-modal-body {
    height: auto;
    width: 790px;
  }
}
.header {
  padding: 35px;
}
@media (max-width: 768px) {
  .header {
    padding: 35px 20px;
  }
}
@media (max-width: 768px) {
  .banner {
    padding: 0 20px;
  }
}
.banner .banner__header h1 {
  font-size: 40px;
  color: #014050;
  letter-spacing: 2px;
}
@media (max-width: 768px) {
  .banner .banner__header h1 {
    letter-spacing: normal;
    font-size: 28px;
  }
}
.banner .banner__header p {
  font-size: 20px;
  max-width: 800px;
  color: #4A7E9F;
}
@media (max-width: 768px) {
  .banner .banner__header p {
    font-size: 18px;
  }
}
.banner .banner__steps p {
  margin: 0;
}
@media (max-width: 768px) {
  .banner .banner__steps .ant-col + .ant-col {
    margin-top: 40px;
  }
}
.banner .banner__content h1 {
  margin: 0 auto;
  max-width: 769px;
  color: #014050;
  font-size: 25px;
}
@media (max-width: 768px) {
  .banner .banner__content h1 {
    font-size: 20px;
  }
}
.banner .banner__slides {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
}
@media (max-width: 768px) {
  .banner .banner__slides {
    padding: 40px 30px;
  }
}
.green-content {
  background: rgba(231, 233, 239, 0.5);
  padding: 60px 0;
}
.green-content h1 {
  font-size: 40px;
  line-height: 60px;
  color: #014050;
  letter-spacing: 2px;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .green-content h1 {
    font-size: 28px;
    line-height: normal;
  }
}
.video-player {
  position: relative;
}
.video-player:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
