.content_2 {
  @media @max768 {
    width: 810px;
    margin: 0px auto;
  }
}

.content1_header {
  @media @max768 {
    width: 100%;
    margin: 0px auto;
  }
}

.content_title {
  @media @max768 {
    padding: 30px;
    width: 60%;
  }
}

.content4 {
  @media @max768 {
    width: 70%
  }

  @media (min-width: 1024px) {
    width: 70%
  }
}

.navigation {
  @media @max768 {
    display: none
  }

}

.burger {
  @media (min-width: 1024px) {
    display: none
  }

  @media @max768 {
    border: 1px solid black
  }
}

.mobile_logo {
  @media (min-width: 1024px) {
    display: none;
  }

  @media @max768 {
    width: 100%;
    margin: 0px auto;
  }
}

.browser_logo {
  @media @max768 {
    display: none
  }
}

.mobile-nav {
  
  @media @max768 {
    width: 300px;
    color: #014050;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.mobile-nav:hover {
  color: #71d97d;
  cursor: pointer;
}

.responsive-modal {
  // @media @max768 {
  @media (max-width: 768px) {
    .ant-modal-content {
      height: auto;
      width: 790px;
      left: -25px;
      // border: 2px solid red;
      position: absolute;

    }

    .ant-modal-body {
      height: auto;
      width: 790px;
      // position: absolute;
    }
  }

  @media (max-width: 426px) {
    .ant-modal-content {
      height: auto;
      width: 790px;
      left: -190px;
      // border: 2px solid red;
      position: absolute;

    }

    .ant-modal-body {
      height: auto;
      width: 790px;
      // position: absolute;
    }
  }

  @media (max-width: 376px) {
    .ant-modal-content {
      height: auto;
      width: 790px;
      left: -215px;
      // border: 2px solid red;
      position: absolute;

    }

    .ant-modal-body {
      height: auto;
      width: 790px;
      // position: absolute;
    }
  }

  @media (max-width: 321px) {
    .ant-modal-content {
      height: auto;
      width: 790px;
      left: -245px;
      // border: 2px solid red;
      position: absolute;

    }

    .ant-modal-body {
      height: auto;
      width: 790px;
      // position: absolute;
    }
  }
}