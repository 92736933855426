.video-player {
    position: relative;
    &:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ( 360px / 640px) * 100%;
	}
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}